/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

const stepFielfdMixin = Vue.extend({
  props: {
    value: Object,
  },
  mounted() {
    (this as any).onInput();
  },
  methods: {
    onInput() {
      this.$emit("input", (this as any).fields);
    },
  },
});

export default stepFielfdMixin;
