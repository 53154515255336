
import Vue from "vue";
import rules from "@/components/account/rules";
import stepFielfdMixin from "../stepFielfdMixin";
import YesNotSelection from "@/components/YesNotSelection.vue";

export default Vue.extend({
  components: { YesNotSelection },
  name: "emergncy-contact",
  mixins: [stepFielfdMixin],
  data() {
    return {
      rules,
      fields: {
        abortion: false, //
        adrenalGlandTumor: false, //
        allergicClose: false, //
        bleedingDisorders: false, //
        celebralPalsy: false, //
        city: "", //
        contactNumber: "", // 17868027461
        controledSubtances: false, //
        country: "", //
        doctorName: "", //
        doctorPhone: "", //17868027461
        email: "", //
        emergencyContactNumber: "", //17868027461
        firstName: "", //
        fullname: "",
        goodHealfh: false, //
        height: "", //
        isMale: false, //
        jointDeformities: false, //
        lastExamDate: "", //2021-07-26T01:17:29.898Z
        lastName: "", //
        midleName: "", //
        nameOfSurgery: "", //
        ocupation: "", //
        relationship: "", //
        rheumatoidArthritis: false, //
        sickleCellDisease: false, //
        streetAddres: "", //
        streetAddresLine2: "",
        surgicalOperations: false, //
        systemicLupus: false, //
        takingMedication: false, //
        thromboticDisorders: false, //
        underMedicalTreatment: false, //
        useAlcoholic: false, //
        useTabacoOrNicotines: false, //
        weight: "", //
        weiringContactLenses: false, //
        zipCode: 0, //
        emergencyFirstName: "",
        emergencyLasttName: "",
      },
    };
  },
  methods: {
    change() {
      //console.log(this.fields.emergencyContactNumber);
    },
  },
});
